import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { useUser } from '../context/Auth'

export default function WithAuth(ProtectedComponent) {
  // eslint-disable-next-line react/display-name
  return (props: AppProps) => {
    if (typeof window !== 'undefined') {
      const Router = useRouter()
      const { user, status } = useUser()

      if (!user && status === "finished") {
        Router.replace('/signin')
        return null
      }
      return <ProtectedComponent {...props} />
    }
    return null
  }
}
