import { Snackbar, Typography } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import { Alert } from '@material-ui/lab'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useLogAuthSignOutMutation } from '../generated/graphql'
import { useUser } from '../context/Auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      minHeight: 66,
      width: '100%',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      padding: '0 1.5rem',
      justifyContent: 'space-between',
      color: theme.palette.common.white,
      position: 'relative',
    },
    actions: {
      display: 'flex',
      height: '100%',
      width: '13rem',
      paddingBottom: '0.5rem',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      alignSelf: 'flex-end',
    },
    action: {
      cursor: 'pointer',
      marginRight: '30px',
      '&:hover': {
        opacity: '0.8',
      },
    },
  })
)

function Header(): ReactElement {
  const classes = useStyles()
  const router = useRouter()
  const [error, setError] = useState<string>('')
  const [openError, setOpenError] = useState(false)
  const { user } = useUser()
  const [logAuthSignOut] = useLogAuthSignOutMutation()

  const signOut = async () => {
    try {
      await logAuthSignOut({
        variables: {
          email: user?.getUsername(),
        },
      })
      await Auth.signOut()

      router.push(`/signin`)
    } catch (e) {
      console.error(e)
      setError(e.message)
      setOpenError(true)
    }
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenError(false)
  }

  const isSpeechAssembler = router.route.includes('speechAssembler')

  // noinspection HtmlUnknownTarget
  return (
    <div
      className={classes.header}
      style={{ display: isSpeechAssembler ? 'none' : 'flex' }}
    >
      <Link href="/sessions">
        <img
          src={require('./../images/slowtalk-logo-light.svg')}
          alt="SlowTalk"
          height={34}
          width={260}
          style={{ objectFit: 'contain', cursor: 'pointer' }}
        />
      </Link>
      <div className={classes.actions}>
        <Link href="/sessions" passHref>
          <Typography variant="button" className={classes.action}>
            Sessions
          </Typography>
        </Link>
        <Typography
          variant="button"
          className={classes.action}
          onClick={() => signOut()}
        >
          Sign Out
        </Typography>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}
export default Header
