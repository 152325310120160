import { AppProps } from 'next/app'
import React from 'react'

export default function WithSnackbar(SnackbarComponent) {
  // eslint-disable-next-line react/display-name
  return (props: AppProps) => {
    if (typeof window !== 'undefined') {
      return <SnackbarComponent {...props} />
    }
    return null
  }
}
