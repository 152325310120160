import withAuth from '../utils/WithAuth'
import withSnackbar from '../utils/WithSnackbar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React, { ReactElement, useEffect } from 'react'
import Header from '../components/header'
import { useRouter } from 'next/router'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      margin: 'auto',
    },
  })
)

function Home(): ReactElement {
  const router = useRouter()
  const { asPath } = router
  const classes = useStyles()

  useEffect(() => {
    if (asPath === '/') {
      router.replace('/sessions')
    } else {
      router.replace(asPath)
    }
  }, [])

  return (
    <div>
      <main>
        <Box className={classes.root}>
          <Header />
          <Box className={classes.container}></Box>
        </Box>
      </main>
    </div>
  )
}

export default withSnackbar(withAuth(Home))
